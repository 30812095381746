.register {
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.register-title {
    font-size: 50px;
}

.register-form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.register-form>label {
    margin: 10px 0;
}

.register-input {
    padding: 10px;
    background-color: white;
    border: none;
}

.register-button {
    margin: 10px;
    cursor: pointer;
    padding: 10px;
    background-color: #222323;
    color: #f6f0f6;
    border-color: #f6f0f6;
    border-width: 2px;
}

.register-login-button {
    margin: 10px;
    cursor: pointer;
    padding: 10px;
    background-color: #f6f0f6;
    color: #222323;
    border-color: #222323;
    border-width: 2px;
}