.post {
    /* width: 385px; */
    width: auto;
    margin: 0px 25px 40px 25px;
}

.post-image {
    width: 100%;
    height: 280px;
    object-fit: cover;
    border-radius: 7px;
}

.post-info {
    /* display: flex; */
    flex-direction: column;
    align-items: left;
}

.post-category {
    color: #969696;
    line-height: 20px;
    margin-top: 15px;
    margin-right: 10px;
    cursor: pointer;
    /* display: inline-block; */
}

.post-categories {
    display: inline-block;
}

.details {
    font-size: 17px;
}

.post-title {
    font-size: 50px;
    font-weight: 700;
    margin-top: 15px;
    cursor: pointer;
    color: #f0f6f0;
    font-family: 'Playfair Display', serif;
    font-weight: 400;
}

.post-date {
    font-style: italic;
    color: #969696;
    margin-top: 15px;
    display: inline-block;
    font-weight: 100;
}

.seperator {
    color: #969696;
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
}

.post-description {
    font-size: 14px;
    color: #444;
    line-height: 24px;
    margin-top: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}