.top {
    width: 100%;
    height: 50px;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    z-index: 999;
    background-color: #222323;
    color: #f0f6f0;
    margin-top: 20px;
}

.top-left {
    /* flex: 3; */
    /* display: flex; */
    align-items: left;
    justify-content: left;
    margin-left: 30px;
}

.top-right {
    /* flex: 3; */
    display: flex;
    align-items: left;
    justify-content: left;
}

.top-center {
    flex: 6;
}

.top-list {
    display: flex;
    justify-content: left;
    padding: 0;
    list-style: none;
}

.top-list-item {
    margin-right: 20px;
    font-size: 18px;
    font-weight: 300;
    cursor: pointer;
}

h1 {
    margin-bottom: -15px;
}

@media screen and (max-width: 500px) {
    .top {
        margin-top: 40px;
        margin-bottom: 50px;
    }
}