.write {
    padding-top: 50px;
}

.write-form {
    position: relative;
    margin: 10px 500px 10px 30px;
}

.write-form-group {
    /* margin-left: 150px; */
    display: flex;
    align-items: left;
}

.write-input {
    font-size: 30px;
    border: none;
    padding: 20px;
    width: 70vw;
    background-color: #222323;
    color: #f0f6f0;
}

.write-input:focus {
    outline: none;
}

.write-text {
    font-size: 20px;
}

.write-submit {
    margin: 10px;
    cursor: pointer;
    padding: 10px;
    background-color: #222323;
    color: #f6f0f6;
    border-color: #f6f0f6;
    border-width: 2px;
}

.submit-wrapper {
    display: inline-block;
}

#publishDate {
    margin: 10px;
}

.ql-toolbar .ql-stroke {
    fill: none;
    stroke: #f0f6f0 !important;
}

.ql-toolbar .ql-fill {
    fill: #f0f6f0 !important;
    stroke: none;
}

.ql-toolbar .ql-picker {
    color: #f0f6f0 !important;
}