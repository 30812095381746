.login {
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-size: cover;
    align-items: center;
}

.login-title {
    font-size: 50px;
}

.login-form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.login-form>label {
    margin: 10px 0;
}

.login-input {
    padding: 10px;
    background-color: white;
    border: none;
}

.login-button {
    margin-top: 20px;
    cursor: pointer;
    background-color: #f6f0f6;
    color: #222323;
    border-color: #f6f0f6;
    border-width: 2px;
    /* border-radius: 10px; */
    padding: 10px
}

.login-button.disabled {
    cursor: not-allowed;
    background-color: blue;
}

.login-register-button {
    margin-top: 10px;
    cursor: pointer;
    padding: 10px;
    background-color: #222323;
    color: #f6f0f6;
    border-color: #f6f0f6;
    border-width: 2px;
    /* border-radius: 10px; */
}

@media screen and (max-width: 500px) {
    .login {
        height: calc(100vh - 400px);
    }
}